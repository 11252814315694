<template>
  <Layout>
    <b-card>
      <FormulateForm v-model="formValues" @submit="resetPassword">
        <FormulateInput
          name="old_password"
          type="password"
          label="Old Password"
          placeholder="Old password here"
          validation="required"
        />
        <FormulateInput
          name="new_password"
          type="password"
          label="New Password"
          placeholder="New password"
          validation="required"
        />
        <FormulateInput
          name="new_password_confirm"
          type="password"
          label="Confirm your password"
          placeholder="Confirm password"
          validation="required|confirm"
          validation-name="Confirmation"
        />
        <div class="text-right">
          <FormulateInput
            type="submit"
            :label="resetting ? 'Resetting...' : 'Change'"
          />
        </div>
      </FormulateForm>
    </b-card>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import { users } from "@/config/api/users";
export default {
  components: {
    Layout,
  },
  data() {
    return {
      formValues: {},
      resetting: false,
    };
  },
  methods: {
    resetPassword() {
      const api = users.changePassword;
      api.data = {
        old_password: this.formValues.old_password,
        new_password: this.formValues.new_password,
      };
      
      this.resetting = true;
      this.generateAPI(api)
        .then(() => {
          this.$bvToast.toast("Password updated successfully!", {
            title: "Password",
            variant: "success",
            solid: true,
          });
        })
        .catch((err) => {
          this.$bvToast.toast(
            `Password is not updated!, ${err.response.data.message}`,
            {
              title: "Password",
              variant: "danger",
              solid: true,
            }
          );
        })
        .finally(() => {
          this.resetting = false;
        });
    },
  },
};
</script>
